import { SWRConfig } from 'swr';
import { fetchAPI } from '../lib/strapiApi';
import { getInStockProductsForStore } from '../lib/stores'
import '../styles/globals.css'
import Script from 'next/script';



function MyApp({ Component, pageProps }) {
  const SWROptions = {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnMount: true,
    refreshInterval: 30000
  }
  return (
    <>

      <SWRConfig value={{ fetcher: fetchAPI, ...SWROptions }}>
        <Script src="https://www.googletagmanager.com/gtag/js?id=G-BMTKFBW8E5" strategy="afterInteractive" />
        <Script id="google-analytics" strategy="afterInteractive">{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-BMTKFBW8E5');`}
        </Script>
        <Component {...pageProps} />
      </SWRConfig>
    </>
  );
}

export default MyApp

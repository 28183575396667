import getConfig from 'next/config';
import Strapi from "strapi-sdk-js";


const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const host = serverRuntimeConfig.API_HOST || publicRuntimeConfig.API_HOST;

export const strapi = new Strapi({
    url: host,
    prefix: '/api'
});

export async function fetchAPI(options) {
    const { path, params } = options
    return await strapi.find(path, params)
}
